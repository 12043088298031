import React from 'react';

import ButtonAction from '../../../components/Buttons/ButtonAction';

import CardBigImage from '../../../components/Cards/CardBigImage';

import {
  Images,
} from '../../../shared/constants';

import {
  SecondSectionDiv,
  VersionMeContent,
  VersionMeTitle,
  VersionMeHorizontalSlideView,
  VersionMeVideoOverlay,
  VersionMeImageBackground,
  VersionMeAnimatedItems,
  VersionMeAboutBtnView,
} from './styled';



const HomeVersionsMeSection: React.FC = () => {
  const imagesItems: { label: string; image: string }[] = [
    {
      label: '1',
      image: Images.personalImg001,
    },
    {
      label: '2',
      image: Images.personalImg002,
    },
    {
      label: '3',
      image: Images.personalImg003,
    },
    {
      label: '4',
      image: Images.personalImg004,
    },
    {
      label: '5',
      image: Images.personalImg005,
    },
    {
      label: '6',
      image: Images.personalImg006,
    },
    {
      label: '7',
      image: Images.personalImg007,
    },
  ];



  const duplicateItems = (items: Array<any>, times = 2) => {
    const duplicatedItems = [];
    for (let i = 0; i < times; i++) {
      duplicatedItems.push(...items);
    }
    return duplicatedItems;
  };


  // Duplica os itens duas vezes
  const duplicatedItems = duplicateItems(imagesItems, 2);



  return (

    <SecondSectionDiv>

      <VersionMeVideoOverlay />



      <VersionMeImageBackground
        src={Images.meVersionsBackground}
      />



      <VersionMeContent>

        <VersionMeTitle>
          {'Várias versões de um eu'.toUpperCase()}
        </VersionMeTitle>



        <VersionMeHorizontalSlideView>

          <VersionMeAnimatedItems>
            {duplicatedItems.map((item: any, index: number) => (
              <CardBigImage
                key={index}
                hover={true}
                title={item?.label}
                image={item?.image}
              />
            ))}
          </VersionMeAnimatedItems>

        </VersionMeHorizontalSlideView>



        <VersionMeAboutBtnView>
          <ButtonAction />
        </VersionMeAboutBtnView>

      </VersionMeContent>

    </SecondSectionDiv>

  );
};



export default HomeVersionsMeSection;
