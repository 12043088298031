export const APPSITES = {
  contaGlobalApp:                   'http://conta-dev.noitada.net/',
  contaOrganizerRegister:           'https://conta-dev.noitada.net/register-company',

  noitadaSite:                      'http://site-dev.noitada.net/',

  appNoitadaSite:                   'http://app-dev.noitada.net/',

  organizerNoitadaSite:             'http://organizer-dev.noitada.net/',

  backstageNoitadaSite:             'http://backstage-dev.noitada.net/',

  storeNoitadaSite:                 'http://store-dev.noitada.net/',
};



const Vars = {
  ...APPSITES,
};



export default Vars;
