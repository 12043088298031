import React from 'react';

// import { Container } from './styles';



const ShopScreen: React.FC = () => {
  return (

    <div>
      <h1>
        ContactsScreen
      </h1>
    </div>

  );
};



export default ShopScreen;
