import React from 'react';

import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';

import AboutMeScreen from '../../screens/AboutMe';
import AccessScreen from '../../screens/Access';
import ContactsScreen from '../../screens/Contacts';
import HomeScreen from '../../screens/Home';
import LinkbioScreen from '../../screens/Linkbio';
import MusicsScreen from '../../screens/Musics';
import MusicsDetailsScreen from '../../screens/Musics/Details';
import PhotosScreen from '../../screens/Photos';
import ProjectsScreen from '../../screens/Projects';
import PromotionsScreen from '../../screens/Promotions';
import ScheduleScreen from '../../screens/Schedule';
import ShopScreen from '../../screens/Shop';
import ValentinesScreen from '../../screens/Valentine';
import VideosScreen from '../../screens/Videos';
import WelcomeScreen from '../../screens/Welcome';

import NameRoutes from '../names';



function Routes() {
  return (

    <BrowserRouter>
      <Switch>

        <Route exact path={NameRoutes.WelcomeScreen} component={WelcomeScreen} />
        <Route exact path={NameRoutes.AccessScreen} component={AccessScreen} />

        <Route exact path={NameRoutes.LinkbioScreen} component={LinkbioScreen} />

        <Route exact path={NameRoutes.HomeScreen} component={HomeScreen} />
        <Route exact path={NameRoutes.AboutMeScreen} component={AboutMeScreen} />
        <Route exact path={NameRoutes.ContactsScreen} component={ContactsScreen} />
        <Route exact path={NameRoutes.MusicsScreen} component={MusicsScreen} />
        <Route exact path={NameRoutes.MusicsDetailsScreen} component={MusicsDetailsScreen} />
        <Route exact path={NameRoutes.PhotosScreen} component={PhotosScreen} />
        <Route exact path={NameRoutes.ProjectsScreen} component={ProjectsScreen} />
        <Route exact path={NameRoutes.PromotionsScreen} component={PromotionsScreen} />
        <Route exact path={NameRoutes.ScheduleScreen} component={ScheduleScreen} />
        <Route exact path={NameRoutes.ShopScreen} component={ShopScreen} />
        <Route exact path={NameRoutes.VideosScreen} component={VideosScreen} />
        <Route exact path={NameRoutes.HomeScreen} component={HomeScreen} />

        <Route exact path={NameRoutes.ValentinesScreen} component={ValentinesScreen} />

        {/*
      
        <Route exact path={NameRoutes.WaitingScreen} component={WelcomeScreen} />
         */}

      </Switch>
    </BrowserRouter>

  );
}



export default Routes;
