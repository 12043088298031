import React, { useState } from 'react';
import './styles.css';



function ValentinesScreen() {
  const accepted = () => {
    alert('Você aceitou namorar comigo! :)');
    window.location.href = 'https://music.youtube.com/watch?v=izGwDsrQ1eQ';
  };


  const newPosition = (min: any, max: any) => {
    return (Math.random() * (max - min) + min) + '%';
  };


  const declined = (event: any) => {
    const btn = event.target;
    btn.style.position = 'absolute';
    btn.style.bottom = newPosition(10, 90);
    btn.style.left = newPosition(10, 90);
    console.log('ops......');
  };



  return (

    <main>

      <div className="loading-heart">
        <span></span>
      </div>


      <h1>Aceita namorar comigo?</h1>


      <section>
        <button className="btn btn-yes" onClick={accepted}>
          SIM
        </button>

        <button className="btn btn-no" onClick={declined} onMouseOver={declined}>
          NÃO
        </button>
      </section>

    </main>

  );
}



export default ValentinesScreen;
