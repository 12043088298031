import React from 'react';
import {
  HeaderDiv,
  TitlesDiv,
  TitleHomeT,
  FirstSubtitleView,
  FirstTextSubtitle,
  SecondSubtitle,
  NameAndUserNameDiv,
  Name,
  Surname,
} from './styled';



const HomeHeader: React.FC = () => {
  return (

    <HeaderDiv>

      <TitlesDiv>

        <TitleHomeT>
          Music
        </TitleHomeT>


        <FirstSubtitleView>

          <FirstTextSubtitle>
            Speaks Louder
          </FirstTextSubtitle>

          <FirstTextSubtitle>
            Than Words
          </FirstTextSubtitle>

        </FirstSubtitleView>


        <SecondSubtitle>
          We add the cerebral and cool to instrumental  music.
        </SecondSubtitle>

      </TitlesDiv>



      <NameAndUserNameDiv>

        <Name>
          Italo
        </Name>

        <Surname>
          Di Sena
        </Surname>

      </NameAndUserNameDiv>

    </HeaderDiv>

  );
};



export default HomeHeader;
