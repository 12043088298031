import React from 'react';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import CardBigImage from '../../Cards/CardBigImage';

import {
  IconSocialApple,
  IconSocialDeezer,
  IconSocialFacebook,
  IconSocialSpotify,
  IconSocialTwitter,
  IconSocialWhatsApp,
  IconSocialYoutube,
  IconStore,
} from '../../Icons';

import {
  ReleaseContainer,
  ReleaseTextContent,
  ReleaseTitleView,
  ReleaseTitle,
  ReleaseSubtitle,
  ReleasesMediasView,
  ReleasesMediaSection,
  ReleaseMediaTitle,
  ReleaseMediaCont,
  MediaReleaseItem,
  MediaShareReleaseItem,
  MediaItemText,
  ReleaseImageView,
} from './styled';



const PersonalReleasesSection: React.FC = () => {
  return (

    <ReleaseContainer>

      <ReleaseTextContent>

        <ReleaseTitleView>

          <ReleaseTitle>
            Me acompanhe
          </ReleaseTitle>


          <ReleaseSubtitle>
            Fique por dentro dos meus lançamentos
          </ReleaseSubtitle>

        </ReleaseTitleView>



        <ReleasesMediasView>

          <ReleasesMediaSection>

            <ReleaseMediaTitle>
              Ouça agora
            </ReleaseMediaTitle>



            <ReleaseMediaCont>

              <MediaReleaseItem
                onClick={() => {
                  // TODO
                }}>
                <IconSocialSpotify
                  color={Colors.white}
                  size={30}
                />

                <MediaItemText>
                  Spotify
                </MediaItemText>
              </MediaReleaseItem>


              <MediaReleaseItem
                onClick={() => {
                  // TODO
                }}>
                <IconSocialDeezer
                  color={Colors.white}
                  size={30}
                />

                <MediaItemText>
                  Deezer
                </MediaItemText>
              </MediaReleaseItem>


              <MediaReleaseItem
                onClick={() => {
                  // TODO
                }}>
                <IconSocialYoutube
                  color={Colors.white}
                  size={30}
                />

                <MediaItemText>
                  Music
                </MediaItemText>
              </MediaReleaseItem>

            </ReleaseMediaCont>

          </ReleasesMediaSection>



          <ReleasesMediaSection>

            <ReleaseMediaTitle>
              Compartilhe
            </ReleaseMediaTitle>



            <ReleaseMediaCont>

              <MediaShareReleaseItem
                onClick={() => {
                  // TODO
                }}>
                <IconSocialFacebook
                  color={Colors.white}
                  size={30}
                />
              </MediaShareReleaseItem>


              <MediaShareReleaseItem
                onClick={() => {
                  // TODO
                }}>
                <IconSocialTwitter
                  color={Colors.white}
                  size={30}
                />
              </MediaShareReleaseItem>


              <MediaShareReleaseItem
                onClick={() => {
                  // TODO
                }}>
                <IconSocialWhatsApp
                  color={Colors.white}
                  size={30}
                />
              </MediaShareReleaseItem>

            </ReleaseMediaCont>

          </ReleasesMediaSection>



          <ReleasesMediaSection>

            <ReleaseMediaTitle>
              Compre em
            </ReleaseMediaTitle>



            <ReleaseMediaCont>

              <MediaReleaseItem
                onClick={() => {
                  // TODO
                }}>
                <IconSocialApple
                  color={Colors.white}
                  size={30}
                />

                <MediaItemText>
                  iTunes
                </MediaItemText>
              </MediaReleaseItem>


              <MediaReleaseItem
                onClick={() => {
                  // TODO
                }}>
                <IconStore
                  color={Colors.white}
                  size={30}
                />
                <MediaItemText>
                  Loja
                </MediaItemText>
              </MediaReleaseItem>

            </ReleaseMediaCont>

          </ReleasesMediaSection>



          <ReleasesMediaSection>

            <ReleaseMediaTitle>
              Inscreva-se
            </ReleaseMediaTitle>



            <ReleaseMediaCont>

              <MediaShareReleaseItem
                onClick={() => {
                  // TODO
                }}>
                <IconSocialYoutube
                  color={Colors.white}
                  size={30}
                />
              </MediaShareReleaseItem>

            </ReleaseMediaCont>

          </ReleasesMediaSection>

        </ReleasesMediasView>

      </ReleaseTextContent>



      <ReleaseImageView>

        <CardBigImage
          hover={false}
          image={Images.persaWaiting}
        />

      </ReleaseImageView>

    </ReleaseContainer>

  );
};



export default PersonalReleasesSection;
