export const APPSITES = {
  contaGlobalApp:                   'http://localhost:1000/',
  contaOrganizerRegister:           'http://localhost:1000/register-company',

  noitadaSite:                      'http://localhost:1369/',
};



const Vars = {
  ...APPSITES,
};



export default Vars;
