import React from 'react';

import {
  Animations,
} from '../../../shared/constants';

import {
  LandingHeaderStyle,
  LandingHeaderContainerStyle,
  // HeaderContent,
  // LandingAppLogo,
  // TextsContent,
  // LandingHeaderTitle,
  // SubtitleLandingHeader,
  // LandingButtonView,
  HeaderChildrenContent,
  LandingMouseDownView,
  LandingMouseDownImage,
  LandingVideoOverlay,
  LandingImageBackground,
  LandingVideoBackground,
} from './styled';



export interface IProps {
  id: string;

  logo?: string;
  video?: any;
  image?: any;
  title?: string;
  subtitle?: string;

  buttonText?: string;
  buttonPress?: any;

  children: any;
}



const LandingHeader: React.FC<IProps> = (props: IProps) => {
  return (

    <LandingHeaderStyle
      id={props.id}>

      <LandingHeaderContainerStyle>

        {props.children && (
          <HeaderChildrenContent>
            {props.children}
          </HeaderChildrenContent>
        )}



        <LandingMouseDownView>
          <LandingMouseDownImage
            src={Animations.mouseScrollDown}
          />
        </LandingMouseDownView>

      </LandingHeaderContainerStyle>



      <LandingVideoOverlay />



      {!props.video && props.image && (
        <LandingImageBackground
          src={props.image}
        />
      )}



      {props.video && !props.image && (
        <LandingVideoBackground
          loop
          autoPlay
          muted
          plays-inline>

          <source src={props.video} type={'video/mp4'} />
          <source src={props.video} type={'video/ogg'} />
          Your browser does not support the video tag.

        </LandingVideoBackground>
      )}

    </LandingHeaderStyle>

  );
};



export default LandingHeader;
