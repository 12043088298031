import React from 'react';

import {
  ScheduleUpcomingGigsItem,
  ScheduleUpcomingGigsItemDate,
  ScheduleUpcomingGigsDateEvent,
  ScheduleUpcomingGigsEvent,
  ScheduleUpcomingGigsInfoEvent,
  ScheduleUpcomingGigsInfoText,
  ScheduleUpcomingGigsLang,
  ScheduleUpcomingGigsLangImage,
  ScheduleUpcomingGigsLangText,
  ScheduleUpcomingGigsTickets,
  ScheduleUpcomingGigsTicketsButton,
} from './styled';



const CardSchedule: React.FC = () => {
  return (

    <ScheduleUpcomingGigsItem>

      <ScheduleUpcomingGigsItemDate>
        <ScheduleUpcomingGigsDateEvent>
          29
        </ScheduleUpcomingGigsDateEvent>

        <ScheduleUpcomingGigsEvent>
          Jul 2023
        </ScheduleUpcomingGigsEvent>
      </ScheduleUpcomingGigsItemDate>


      <ScheduleUpcomingGigsItemDate>
        <ScheduleUpcomingGigsEvent>
          Event
        </ScheduleUpcomingGigsEvent>
      </ScheduleUpcomingGigsItemDate>


      <ScheduleUpcomingGigsInfoEvent>
        <ScheduleUpcomingGigsInfoText>
          Tomorrowland
        </ScheduleUpcomingGigsInfoText>

        <ScheduleUpcomingGigsLang>
          <ScheduleUpcomingGigsLangImage
            src="https://www.arminvanbuuren.com/wp-content/themes/armin/landen/be.png"
          />

          <ScheduleUpcomingGigsLangText>
            Boom, Belgium
          </ScheduleUpcomingGigsLangText>
        </ScheduleUpcomingGigsLang>
      </ScheduleUpcomingGigsInfoEvent>


      <ScheduleUpcomingGigsTickets>
        <ScheduleUpcomingGigsTicketsButton
          href="#"
          target="_blank">
          TICKETS
        </ScheduleUpcomingGigsTicketsButton>
      </ScheduleUpcomingGigsTickets>

    </ScheduleUpcomingGigsItem>

  );
};



export default CardSchedule;
