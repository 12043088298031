import React from 'react';

import {
  ReleaseImageBorder,
  ReleaseImageView,
  ReleaseItemImage,
} from './styled';



export interface IProps {
  hover: boolean;
  title?: string;
  image: string;
}



const CardBigImage: React.FC<IProps> = (props: IProps) => {
  return (


    <ReleaseImageBorder>
      <ReleaseImageView>

        <ReleaseItemImage
          src={props.image}
        />

      </ReleaseImageView>
    </ReleaseImageBorder>

  );
};



export default CardBigImage;
