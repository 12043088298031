import styled,
{
  keyframes,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  TitleLandingHeader,
} from '../../../styles/styled.layout';



const slide = keyframes`
  0% {
    transform: translateX(0);
  }

  100% { 
    transform: translateX(-50%);
  }
`;



export const SecondSectionDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding-top: ${Sizes.desktopPaddingVertical};
  padding-bottom: ${Sizes.desktopPaddingVertical};
  background-color: ${Colors.appBackground};
`;



export const VersionMeVideoOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(37, 20, 33,0.95);
  z-index: 3;
`;

export const VersionMeImageBackground = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
`;



export const VersionMeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;


export const VersionMeTitle = styled(TitleLandingHeader)`
`;

export const VersionMeHorizontalSlideView = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  margin-top: 60px;
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
`;

export const VersionMeAnimatedItems = styled.div`
  display: flex;
  flex-direction: row;
  animation: ${slide} 30s linear infinite;
  gap: 40px;
`;



export const VersionMeAboutBtnView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
`;
