import React from 'react';

// import { Container } from './styles';

const FooterPartner: React.FC = () => {
  return <div />;
};



export default FooterPartner;
