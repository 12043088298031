import React,
{
  useState,
} from 'react';

import './styled.css';
// import { Container } from './styles';

const BarMusic: React.FC = () => {
  const [paused, setPaused] = useState(false);

  // https://codepen.io/italodisena/pen/ZEMjvYg

  return (

    <div className='play-bar-content'>

      <div className={`now playing ${paused ? 'paused' : ''}`} id="music">
        <span className="bar n1">A</span>
        <span className="bar n2">B</span>
        <span className="bar n3">c</span>
        <span className="bar n4">D</span>
        <span className="bar n5">E</span>
        <span className="bar n6">F</span>
        <span className="bar n7">G</span>
        <span className="bar n8">H</span>
      </div>


      <button
        onClick={() => {
          setPaused(!paused);
        }}>
        Play
      </button>

    </div>

  );
};


export default BarMusic;
