import React from 'react';
import { Button } from './styled';


interface IButtonProps {
  width?: string,
  backgroundColor?: string,
  fontColor?: string,
  borderColor?: string,
  borderRadius?: string,
  buttonText?: string,
}



const ButtonAction: React.FC<IButtonProps> = ({ width = '150px', backgroundColor = '#af00dd', fontColor = '#FFF', borderColor = 'transparent', borderRadius = '30px',
  buttonText = 'Song Collection' }): JSX.Element => {
  return (

    <Button $width={width} $backgroundColor={backgroundColor} $fontColor={fontColor} $borderColor={borderColor} $borderRadius={borderRadius}>
      {buttonText}
    </Button>

  );
};



export default ButtonAction;
