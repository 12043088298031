import styled from 'styled-components';


interface IProps {
  transparent?: boolean;
}


export const Button = styled.button<{ $width: string, $backgroundColor?: string, $fontColor?: string, $borderColor?:string, $borderRadius?:string }>`
width: ${(props) => props.$width};
height: 40px;
background-color: ${(props) => props.$backgroundColor};
color: ${(props) => props.$fontColor};
border: 1px solid ${(props) => props.$borderColor};
border-radius: ${(props) => props.$borderRadius};
`;



