import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export const ReleaseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${'70px'};
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  padding-bottom: ${'70px'};
  background-color: ${Colors.black};
`;

export const ReleaseTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
`;



export const ReleaseTitleView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ReleaseTitle = styled.h1`
  color: ${Colors.tertiary};
  font-size: 74px;
  font-weight: bolder;
  text-transform: uppercase;
`;

export const ReleaseSubtitle = styled.p`
  margin-top: 5px;
  color: ${Colors.tertiary};
  font-size: 28px;
  font-weight: bolder;
  text-transform: uppercase;
`;



export const ReleasesMediasView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ReleasesMediaSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ReleaseMediaTitle = styled.h1`
  color: ${Colors.white};
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
`;



export const ReleaseMediaCont = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
`;

export const MediaReleaseItem = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 25px;
`;

export const MediaShareReleaseItem = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
`;

export const MediaItemText = styled.p`
  margin-left: 10px;
  color: ${Colors.white};
  font-size: 16px;
`;


export const ReleaseImageView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
