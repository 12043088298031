// // Backgrounds
// import _backgroundIndex2 from '../assets/backgrounds/background_index2.png';
// import _backgroundIndex3 from '../assets/backgrounds/background_index3.png';
// import _backgroundPartner from '../assets/backgrounds/background-partner.png';
// import _backgroundPROOffers from '../assets/backgrounds/background-pro-offers.png';
// import _backgroundPROSolutions from '../assets/backgrounds/background-pro-solutions.png';
// import _backgroundPRO from '../assets/backgrounds/background-pro.png';
// import _linkExtensions from '../assets/backgrounds/link-extensions.png';
// import _teamAll from '../assets/backgrounds/team-all.png';


// // Drawables
// import _iconLove from '../assets/drawables/icon-love.png';


// import _bankTransfer from '../assets/images/bank-account-transfer.png';
// import _bankPaymentCalendar from '../assets/images/bank-payment-calendar.png';
// import _companyAnalysis from '../assets/images/company-analysis.png';


// // Erros
// import _error403 from '../assets/images/error_403.png';
// import _error404 from '../assets/images/error_404.png';
// import _error500 from '../assets/images/error_500.png';


// import _howWorksStep from '../assets/images/how-works-step.png';

// import _jobNoitada from '../assets/images/job-noitada.png';
// import _jobPersons from '../assets/images/job-persons.png';

// import _kitIcon from '../assets/images/kit_icon.png';
// import _kitLogo from '../assets/images/kit_logo.png';
// import _kitPhotos from '../assets/images/kit_photos.png';
// import _kitUI from '../assets/images/kit_ui.png';


// // Markets
// import _marketAppStore from '../assets/images/market_app_store.png';
// import _marketGooglePlay from '../assets/images/market_google_play.png';
// import _marketWindowsStore from '../assets/images/market_windows_store.png';
// import _moneySymbols from '../assets/images/money-symbols.png';


// import _placeBa from '../assets/images/place-ba.png';
// import _placeBh from '../assets/images/place-bh.png';
// import _placeSp from '../assets/images/place-sp.png';

// import _planBasic from '../assets/images/plan-basic.png';
// import _planPlus from '../assets/images/plan-plus.png';


// // Separators
// import _separatorHorizontal from '../assets/images/separator_horizontal.png';
// import _separatorVertical from '../assets/images/separator_vertical.png';


import _meVersionsBackground from '../assets/backgrounds/me-versions-background.jpg';
import _welcomeBackground from '../assets/backgrounds/welcome-background.jpg';
import _persaWaiting from '../assets/images/persa-waiting.jpg';

// import _serviceAdvancedSettings from '../assets/images/service-advanced-settings.png';
// import _serviceContacts from '../assets/images/service-contacts.png';
// import _serviceEntrances from '../assets/images/service-entrances.png';
// import _serviceTickets from '../assets/images/service-tickets.png';

// import _tools1 from '../assets/images/tools-1.png';
// import _tools2 from '../assets/images/tools-2.png';
// import _tools3 from '../assets/images/tools-3.png';
// import _tools4 from '../assets/images/tools-4.png';
// import _tools5 from '../assets/images/tools-5.png';
// import _tools6 from '../assets/images/tools-6.png';
// import _tools7 from '../assets/images/tools-7.png';
// import _tools8 from '../assets/images/tools-8.png';
// import _tools9 from '../assets/images/tools-9.png';
// import _toolsPlus from '../assets/images/tools-plus.png';

// import _tutorial1 from '../assets/images/tutorial-1.png';
// import _tutorial2 from '../assets/images/tutorial-2.png';
// import _tutorial3 from '../assets/images/tutorial-3.png';
// import _tutorial4 from '../assets/images/tutorial-4.png';


// // Logos
// import _logoNoitadaAppIcon from '../assets/logos/icon_mobile_ios_app.png';
// import _logoOrganizerAppIcon from '../assets/logos/icon_mobile_ios_organizer.png';
// import _logoPROIconColor from '../assets/logos/icon_pro_logo_color.png';
// import _logoTvIconWhite from '../assets/logos/icon_tv_logo_color.png';
// import _logoNoitadaFullWhite from '../assets/logos/logo_full_app_white.png';
import _logoPersasNameWhite from '../assets/logos/logo_full_persas_white.png';
// import _logoNoitadaIconBlack from '../assets/logos/logo_icon_app_black.png';
// import _logoNoitadaIconWhite from '../assets/logos/logo_icon_app_white.png';
import _logoPersasIconWhite from '../assets/logos/logo_icon_persas_white.png';

import _personalImg001 from '../assets/personal/img-001.png';
import _personalImg002 from '../assets/personal/img-002.png';
import _personalImg003 from '../assets/personal/img-003.png';
import _personalImg004 from '../assets/personal/img-004.png';
import _personalImg005 from '../assets/personal/img-005.png';
import _personalImg006 from '../assets/personal/img-006.png';
import _personalImg007 from '../assets/personal/img-007.png';
import _personalImg008 from '../assets/personal/img-008.png';
import _personalImg009 from '../assets/personal/img-009.png';
import _personalImg010 from '../assets/personal/img-010.png';
import _personalImg011 from '../assets/personal/img-011.png';
import _personalImg012 from '../assets/personal/img-012.png';
import _personalImg013 from '../assets/personal/img-013.png';
import _personalImg014 from '../assets/personal/img-014.png';


// // Screenshots
// import _noitadaHome from '../assets/screenshots/noitada-home.png';
// import _noitadaPcMobile from '../assets/screenshots/noitada-pc-mobile.png';
// import _organizerHome from '../assets/screenshots/organizer-home.png';



// export const ABOUT = {
//   companyAnalysis:                  _companyAnalysis,

//   bankTransfer:                     _bankTransfer,
//   bankPaymentCalendar:              _bankPaymentCalendar,
//   howWorksStep:                     _howWorksStep,

//   kitIcon:                          _kitIcon,
//   kitLogo:                          _kitLogo,
//   kitPhotos:                        _kitPhotos,
//   kitUI:                            _kitUI,
// };


export const BACKGROUNDS = {
  meVersionsBackground:             _meVersionsBackground,
  welcomeBackground:                _welcomeBackground,
//   backgroundIndex2:                 _backgroundIndex2,
//   backgroundIndex3:                 _backgroundIndex3,
//   backgroundPartner:                _backgroundPartner,

//   backgroundPRO:                    _backgroundPRO,
//   backgroundPROOffers:              _backgroundPROOffers,
//   backgroundPROSolutions:           _backgroundPROSolutions,

//   linkExtensions:                   _linkExtensions,
//   teamAll:                          _teamAll,
};


// export const DRAWABLES = {
//   iconLove:                         _iconLove,
// };


// export const ERRORS = {
//   error403:                         _error403,
//   error404:                         _error404,
//   error500:                         _error500,
// };


export const IMAGES = {
  persaWaiting:                     _persaWaiting,

//   planBasic:                        _planBasic,
//   planPlus:                         _planPlus,

//   jobNoitada:                       _jobNoitada,
//   placeBa:                          _placeBa,
//   placeSp:                          _placeSp,
//   placeBh:                          _placeBh,
//   jobPersons:                       _jobPersons,
//   serviceAdvancedSettings:          _serviceAdvancedSettings,
//   serviceContacts:                  _serviceContacts,
//   serviceEntrances:                 _serviceEntrances,
//   serviceTickets:                   _serviceTickets,
  
//   tools1:                           _tools1,
//   tools2:                           _tools2,
//   tools3:                           _tools3,
//   tools4:                           _tools4,
//   tools5:                           _tools5,
//   tools6:                           _tools6,
//   tools7:                           _tools7,
//   tools8:                           _tools8,
//   tools9:                           _tools9,
//   toolsPlus:                        _toolsPlus,
  
//   tutorial1:                        _tutorial1,
//   tutorial2:                        _tutorial2,
//   tutorial3:                        _tutorial3,
//   tutorial4:                        _tutorial4,
};


export const LOGOS = {
  // logoNoitadaFullWhite:             _logoNoitadaFullWhite,
  // logoNoitadaIconBlack:             _logoNoitadaIconBlack,
  // logoNoitadaIconWhite:             _logoNoitadaIconWhite,

  // logoNoitadaAppIcon:               _logoNoitadaAppIcon,
  // logoOrganizerAppIcon:             _logoOrganizerAppIcon,

  // logoPROIconColor:                 _logoPROIconColor,

  // logoTvIconWhite:                  _logoTvIconWhite,

  logoPersasNameWhite:              _logoPersasNameWhite,
  logoPersasIconWhite:              _logoPersasIconWhite,
};


// export const MARKETS = {
//   marketGooglePlay:                 _marketGooglePlay,
//   marketAppStore:                   _marketAppStore,
//   marketWindowsStore:               _marketWindowsStore,
// };


export const PERSONAL = {
  personalImg001:                     _personalImg001,
  personalImg002:                     _personalImg002,
  personalImg003:                     _personalImg003,
  personalImg004:                     _personalImg004,
  personalImg005:                     _personalImg005,
  personalImg006:                     _personalImg006,
  personalImg007:                     _personalImg007,
  personalImg008:                     _personalImg008,
  personalImg009:                     _personalImg009,
  personalImg010:                     _personalImg010,
  personalImg011:                     _personalImg011,
  personalImg012:                     _personalImg012,
  personalImg013:                     _personalImg013,
  personalImg014:                     _personalImg014,
};


// export const SCREENSHOTS = {
//   noitadaHome:                      _noitadaHome,
//   noitadaPcMobile:                  _noitadaPcMobile,
//   organizerHome:                    _organizerHome,
// }; 


// export const SEPARATORS = {
//   separatorHorizontal:              _separatorHorizontal,
//   separatorVertical:                _separatorVertical,
// };



const Images = {
  // ...ABOUT,
  ...BACKGROUNDS,
  // ...DRAWABLES,
  // ...ERRORS,
  ...IMAGES,
  ...LOGOS,
  // ...MARKETS,
  ...PERSONAL,
  // ...SCREENSHOTS,
  // ...SEPARATORS,
};



export default Images;
