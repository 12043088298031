import styled from 'styled-components';

export const HeaderWelcomeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  background: green;
`;



export const ALogo = styled.a`
  width: 160px;
  height: auto;
`;

export const AImageLogo = styled.img`
  width: 165px;
  max-width: 165px;
  height: auto;
`;



export const MsoundInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: gold;
`;

export const MsoundLabel = styled.p`
-webkit-text-size-adjust: 100%;
font-size: 15px;
-webkit-font-smoothing: antialiased;
color: #fff;
visibility: visible;
pointer-events: all;
text-align: center;
box-sizing: border-box;
font-family: reload,arial narrow,Arial,Helvetica,sans-serif;
font-weight: 300;
line-height: 1;
text-transform: uppercase;
display: block;
margin-top: 13px;
`;



export const MsoundBottomView = styled.a`
position: absolute;
bottom: 40px;
right: 60px;
display: flex;
overflow: hidden;
height: 40px;
padding: 10px 20px;
background-color: #000;
box-shadow: inset 0 0 0 1px #000;
`;


export const MsoundBottomSpan = styled.span`
font-weight: 300;
line-height: 21px;
text-align: center;
text-transform: uppercase;
white-space: nowrap;
color: #fff;
font-family: reload,arial narrow,Arial,Helvetica,sans-serif;
`;


export const MsoundBottomIcon = styled.div`
  height: 30px;
  widht: 30px;
`;
