import React from 'react';

import CardFollowArea from '../../components/Cards/CardFollowArea';
import CardSchedule from '../../components/Cards/CardSchedule';

import {
  Screen,
} from '../../components/Composh/web';

import {
  ScheduleContainer,
  ScheduleMapContainer,
  ScheduleListContainer,
  ScheduleUpcomingGigsTitle,
  ScheduleUpcomingGigsContainer,
  ScheduleUpcomingGigsHeader,
  ScheduleUpcomingGigsHeaderEventInfo,
  ScheduleUpcomingGigsHeaderDate,
  ScheduleUpcomingSideBarBottom,
  ScheduleUpcomingGigsContHeader,
  ScheduleUpcomingTourOverview,
  ScheduleUpcomingTourFilter,
} from './styled';



const ScheduleScreen: React.FC = () => {
  return (

    <Screen>

      <ScheduleContainer>

        <ScheduleMapContainer>

        </ScheduleMapContainer>



        <ScheduleListContainer>

          <ScheduleUpcomingGigsContHeader>
            <ScheduleUpcomingGigsTitle>
              Upcoming gigs
            </ScheduleUpcomingGigsTitle>


            <ScheduleUpcomingTourOverview>

              <ScheduleUpcomingTourFilter>
                <select id="input" name="input-name">
                  <option value="All dates" selected>All dates</option>
                  <option value="2023">2023</option>
                </select>
              </ScheduleUpcomingTourFilter>

              <ScheduleUpcomingTourFilter>
                <select id="input" name="input-name">
                  <option value="Worldwide">Worldwide</option>
                  <option value="North-America">North America</option>
                  <option value="South-America">South America</option>
                  <option value="Europe">Europe</option>
                  <option value="Australia">Australia</option>
                  <option value="Africa">Africa</option>
                  <option value="Asia">Asia</option>
                </select>
              </ScheduleUpcomingTourFilter>
            </ScheduleUpcomingTourOverview>

          </ScheduleUpcomingGigsContHeader>



          <ScheduleUpcomingGigsContainer>

            <ScheduleUpcomingGigsHeader>
              <ScheduleUpcomingGigsHeaderDate>
                Date
              </ScheduleUpcomingGigsHeaderDate>

              <ScheduleUpcomingGigsHeaderDate>
                Type
              </ScheduleUpcomingGigsHeaderDate>

              <ScheduleUpcomingGigsHeaderEventInfo>
                Event
              </ScheduleUpcomingGigsHeaderEventInfo>
            </ScheduleUpcomingGigsHeader>



            <CardSchedule />


            <CardFollowArea />

          </ScheduleUpcomingGigsContainer>



          <ScheduleUpcomingSideBarBottom>
            The above dates are 100% confirmed. Check schedule regularly for updates.
          </ScheduleUpcomingSideBarBottom>

        </ScheduleListContainer>

      </ScheduleContainer>

    </Screen >

  );
};



export default ScheduleScreen;
