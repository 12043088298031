import React from 'react';

// import { Container } from './styles';



const LinkbioScreen: React.FC = () => {
  // https://linkme.bio/grupoprotection

  return (

    <body >
      <div className="container-linkme" id="linkme-profile">
        <div id="wrapper">
          <div>
            <div id="user-area">
              <div id="header" >
                <img
                  src="https://img.linkme.bio/img/2021/07/04150633/Foto-de-perfil-divertido-com-frase-engracada-1-300x300.png"
                  id="preview-profile-image"
                  width="96px"
                  height="96px"
                  title="@grupoprotection"
                  alt="grupoprotection"
                />
                <h1>Grupo Protection</h1>
              </div>
              <div id="presentation">
                📒Escola de formação <br />
                💂🏽‍♂️💂🏼‍♀️Segurança Privada<br />
                📍Referência no Amazonas<br />
                📲 Vem pra Protection!
              </div>
            </div>
            <div
              id="page-bg"
            ></div>
            <div id="content" >
              <ul id="social" >
                <a href="https&colon;//web.facebook.com/grupoprotection" id="social-facebook" >
                  <svg width="40" height="40" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M384.455 449.152C298.905 458.283 213.095 458.283 127.545 449.152C93.4329 445.511 66.4889 418.567 62.8481 384.452C53.7173 298.902 53.7173 213.095 62.8481 127.545C66.4889 93.4329 93.4329 66.4889 127.545 62.8481C213.095 53.7173 298.902 53.7173 384.452 62.8481C418.567 66.4889 445.511 93.4329 449.152 127.545C458.283 213.095 458.283 298.902 449.152 384.452C445.511 418.567 418.57 445.511 384.455 449.152Z"
                      fill="url(#paint_fb_linear)"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M218.843 455.428V305.93H168V245.5H218.843V197.887C218.843 146.156 250.396 118 296.466 118C318.54 118 337.498 119.66 343 120.391V174.445H311.049C285.992 174.445 281.153 186.398 281.153 203.863V245.5H337.697L329.941 305.93H281.153V455.738C260.384 456.171 239.611 456.068 218.843 455.428Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient id="paint_fb_linear" x1="188.808" y1="112.239" x2="289.979" y2="328.699" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4263A7" />
                        <stop offset="1" stopColor="#3F66B7" />
                      </linearGradient>
                    </defs>
                  </svg>
                </a>
                <a href="https&colon;//www.instagram.com/grupoprotection/" id="social-instagram" >
                  <svg width="40" height="40" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M82.0919 83.7708C50.7489 116.321 57.1637 150.897 57.1637 255.941C57.1637 343.172 41.9409 430.62 121.611 451.206C146.49 457.603 366.921 457.603 391.766 451.173C424.937 442.616 451.926 415.716 455.616 368.81C456.131 362.264 456.131 149.734 455.599 143.055C451.677 93.0921 420.916 64.2974 380.382 58.4654C371.093 57.1195 369.231 56.7207 321.569 56.6376C152.506 56.7207 115.446 49.1939 82.0919 83.7708V83.7708Z"
                      fill="url(#paint_ig_linear)"
                    />
                    <path
                      d="M256.556 108.794C196.213 108.794 138.912 103.427 117.025 159.587C107.984 182.783 109.297 212.907 109.297 256.04C109.297 293.891 108.084 329.464 117.025 352.477C138.862 408.671 196.629 403.287 256.523 403.287C314.306 403.287 373.885 409.302 396.037 352.477C405.095 329.049 403.765 299.374 403.765 256.04C403.765 198.518 406.939 161.382 379.036 133.501C350.784 105.255 312.578 108.794 256.489 108.794H256.556ZM243.361 135.329C369.231 135.129 385.252 121.139 376.411 315.491C373.27 384.229 320.92 376.686 256.572 376.686C139.244 376.686 135.87 373.329 135.87 255.974C135.87 137.256 145.177 135.395 243.361 135.295V135.329ZM335.163 159.77C325.408 159.77 317.497 167.679 317.497 177.432C317.497 187.186 325.408 195.095 335.163 195.095C344.918 195.095 352.828 187.186 352.828 177.432C352.828 167.679 344.918 159.77 335.163 159.77V159.77ZM256.556 180.423C214.793 180.423 180.94 214.286 180.94 256.04C180.94 297.795 214.793 331.641 256.556 331.641C298.319 331.641 332.155 297.795 332.155 256.04C332.155 214.286 298.319 180.423 256.556 180.423V180.423ZM256.556 206.958C321.452 206.958 321.535 305.123 256.556 305.123C191.676 305.123 191.576 206.958 256.556 206.958Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient id="paint_ig_linear" x1="82.8567" y1="429.94" x2="453.484" y2="109.103" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FFDD55" />
                        <stop offset="0.5" stopColor="#FF543E" />
                        <stop offset="1" stopColor="#C837AB" />
                      </linearGradient>
                    </defs>
                  </svg>
                </a>
                <a href="https&colon;//www.youtube.com/channel/UC2p0Ra0qCFyVbNd2e8ud2Sw" id="social-youtube" >
                  <svg width="40" height="40" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M384.455 449.152C298.905 458.283 213.095 458.283 127.545 449.152C93.4329 445.511 66.4889 418.567 62.8481 384.452C53.7173 298.902 53.7173 213.095 62.8481 127.545C66.4889 93.4329 93.4329 66.4889 127.545 62.8481C213.095 53.7173 298.902 53.7173 384.452 62.8481C418.567 66.4889 445.511 93.4329 449.152 127.545C458.283 213.095 458.283 298.902 449.152 384.452C445.511 418.567 418.57 445.511 384.455 449.152Z"
                      fill="url(#paint_yt_linear)"
                    />
                    <path
                      d="M361.172 189.75C352.976 180.012 337.846 176 308.937 176H204.061C174.521 176 159.121 180.238 150.97 190.607C143 200.751 143 215.673 143 236.321V275.679C143 315.668 152.456 336 204.061 336H308.937C333.974 336 347.888 332.484 356.849 323.873C366.08 315.036 369.998 300.655 369.998 275.679V236.321C370.043 214.546 369.413 199.533 361.172 189.75ZM288.763 261.387L241.121 286.318C240.04 286.859 238.87 287.13 237.744 287.13C236.438 287.13 235.132 286.769 233.961 286.048C231.755 284.74 230.449 282.351 230.449 279.781V230.145C230.449 227.575 231.8 225.231 233.961 223.878C236.168 222.526 238.87 222.436 241.121 223.653L288.763 248.403C291.195 249.666 292.726 252.145 292.726 254.895C292.681 257.646 291.195 260.125 288.763 261.387Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient id="paint_yt_linear" x1="188.808" y1="112.239" x2="289.979" y2="328.699" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF0000" />
                        <stop offset="1" stopColor="#EC0000" />
                      </linearGradient>
                    </defs>
                  </svg>
                </a>
              </ul>
              <div id="links" >
                <a
                  href="https&colon;//www.youtube.com/watch?v=tn3EErTSpeU"
                  className="counter"
                  data-link-title="Venha para Protection"
                  data-link-url="https&colon;//www.youtube.com/watch?v=tn3EErTSpeU"
                  data-link-id="350627"
                  data-link-author-id="90481"
                  data-link-author="grupoprotection"

                >
                  <iframe
                    src="https://www.youtube.com/embed/tn3EErTSpeU?autoplay=1&origin=https://linkme.bio"
                  ></iframe>
                </a>
                <a
                  href="https://wa.me/+5592993028181?text=QAP%20GRUPO%20PROTECTION"
                  className="counter animate"
                  data-link-title="Whatsapp"
                  data-link-url="https://wa.me/+5592993028181?text=QAP%20GRUPO%20PROTECTION"
                  data-link-id="350627"
                  data-link-author-id="90481"
                  data-link-author="grupoprotection"
                >
                  <div
                    className="link-icon"

                  ></div>
                  <span>Whatsapp</span>
                </a>
                <a
                  href="https&colon;//ead.grupoprotection.com/"
                  className="counter"
                  data-link-title="Ead Protection"
                  data-link-url="https&colon;//ead.grupoprotection.com/"
                  data-link-id="350627"
                  data-link-author-id="90481"
                  data-link-author="grupoprotection"
                >
                  <div
                    className="link-icon"

                  ></div>
                  <span>Ead Protection</span>
                </a>
                <a
                  href="https&colon;//www.instagram.com/grupoprotection/"
                  className="counter animate"
                  data-link-title="Instagram"
                  data-link-url="https&colon;//www.instagram.com/grupoprotection/"
                  data-link-id="350627"
                  data-link-author-id="90481"
                  data-link-author="grupoprotection"
                >
                  <div
                    className="link-icon"

                  ></div>
                  <span>Instagram</span>
                </a>
                <a
                  href="https&colon;//web.facebook.com/grupoprotection"
                  className="counter animate"
                  data-link-title="Facebook"
                  data-link-url="https&colon;//web.facebook.com/grupoprotection"
                  data-link-id="350627"
                  data-link-author-id="90481"
                  data-link-author="grupoprotection"
                >
                  <div
                    className="link-icon"

                  ></div>
                  <span>Facebook</span>
                </a>
                <a
                  href="https&colon;//www.grupoprotection.com/"
                  className="counter animate"
                  data-link-title="Site Protection"
                  data-link-url="https&colon;//www.grupoprotection.com/"
                  data-link-id="350627"
                  data-link-author-id="90481"
                  data-link-author="grupoprotection"
                >
                  <div
                    className="link-icon"

                  ></div>
                  <span>Site Protection</span>
                </a>
                <a
                  href="https&colon;//www.google.com.br/maps/place/Grupo+Protection+-+Formação+de+Vigilante/@-3.1237403,-60.0296555,17z/data=!3m1!4b1!4m5!3m4!1s0x926c0f89b41b2247&colon;0xa09ebb48682d78a6!8m2!3d-3.1237457!4d-60.0274615"
                  className="counter"
                  data-link-title="Localização"
                  data-link-url="https&colon;//www.google.com.br/maps/place/Grupo+Protection+-+Formação+de+Vigilante/@-3.1237403,-60.0296555,17z/data=!3m1!4b1!4m5!3m4!1s0x926c0f89b41b2247&colon;0xa09ebb48682d78a6!8m2!3d-3.1237457!4d-60.0274615"
                  data-link-id="350627"
                  data-link-author-id="90481"
                  data-link-author="grupoprotection"
                >
                  <div
                    className="link-icon"
                  ></div>
                  <span>Localização</span>
                </a>
              </div>
            </div>
          </div>
          <div id="footer" >
            <div id="footer-brand" >
              <a href="https://linkme.bio/?utm_source=linkmebio&utm_medium=profile&utm_content=grupoprotection" aria-label="Crie sua página grátis">
                <svg width="110" height="27" viewBox="0 0 110 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32.4927 19.125V6.78711H34.3244V19.125H32.4927Z" fill="#1C1C1C" />
                  <path
                    d="M36.5251 9.07765C36.0672 8.60763 36.0672 7.88792 36.5251 7.43259C36.983 6.96257 37.7364 6.96257 38.1943 7.43259C38.6522 7.88792 38.6522 8.59294 38.1795 9.07765C37.7216 9.53298 36.9978 9.53298 36.5251 9.07765ZM36.4365 19.1243V10.6786H38.2682V19.1243H36.4365Z"
                    fill="#1C1C1C"
                  />
                  <path
                    d="M48.1358 13.94V19.1249H46.3041V14.131C46.3041 12.8678 45.5803 12.1775 44.3986 12.1775C43.1134 12.1775 42.2419 12.9706 42.2419 14.6157V19.1396H40.4102V10.6793H42.2419V11.7662C42.8032 10.8996 43.6747 10.459 44.8712 10.459C46.8211 10.459 48.1358 11.7809 48.1358 13.94Z"
                    fill="#1C1C1C"
                  />
                  <path d="M57.6047 19.1246H55.4184L51.9323 15.2176V19.1246H50.1006V7.30078H51.9323V14.4098L55.2264 10.679H57.4717L53.7345 14.821L57.6047 19.1246Z" fill="#1C1C1C" />
                  <path
                    d="M71.3425 13.896V19.1396H69.5108V14.0282C69.5108 12.8678 68.8757 12.1922 67.8564 12.1922C66.719 12.1922 66.0099 12.9413 66.0099 14.4247V19.1543H64.1782V14.0429C64.1782 12.8825 63.6021 12.2069 62.5829 12.2069C61.475 12.2069 60.6921 12.9706 60.6921 14.4394V19.169H58.8604V10.6793H60.6921V11.6928C61.2386 10.8702 62.0511 10.459 63.1146 10.459C64.2078 10.459 65.0054 10.8996 65.5224 11.7956C66.0838 10.8996 66.9553 10.459 68.1223 10.459C70.0426 10.459 71.3425 11.7956 71.3425 13.896Z"
                    fill="#1C1C1C"
                  />
                  <path
                    d="M74.7549 15.6878C75.0208 16.9657 76.0696 17.6854 77.4729 17.6854C78.4479 17.6854 79.1717 17.3329 79.6592 16.6426L81.1659 17.5238C80.3387 18.7576 79.0978 19.3598 77.4434 19.3598C76.0696 19.3598 74.947 18.9339 74.0902 18.1114C73.2334 17.2595 72.8198 16.2019 72.8198 14.9241C72.8198 13.6609 73.2482 12.6034 74.0754 11.7661C74.9027 10.8995 75.9958 10.4883 77.3105 10.4883C78.5513 10.4883 79.5705 10.9289 80.3682 11.7955C81.1659 12.6621 81.5795 13.7196 81.5795 14.9534C81.5795 15.1444 81.5647 15.3941 81.5057 15.7172H74.7549V15.6878ZM79.733 14.1897C79.4819 12.8237 78.507 12.1187 77.3105 12.1187C75.9515 12.1187 75.0061 12.9118 74.7402 14.1897H79.733Z"
                    fill="#1C1C1C"
                  />
                  <path
                    d="M84.8879 18.0679C84.8879 18.7582 84.3118 19.331 83.6175 19.331C82.9233 19.331 82.3472 18.7582 82.3472 18.0679C82.3472 17.3775 82.8937 16.8047 83.6175 16.8047C84.3118 16.8047 84.8879 17.3775 84.8879 18.0679Z"
                    fill="#1C1C1C"
                  />
                  <path
                    d="M94.6378 11.7512C95.465 12.6178 95.8786 13.6607 95.8786 14.9092C95.8786 16.143 95.465 17.2005 94.6378 18.0671C93.8106 18.9337 92.8208 19.345 91.6539 19.345C90.3687 19.345 89.379 18.8749 88.6847 17.9055V19.1246H86.853V7.30078H88.6847V11.8834C89.379 10.9434 90.3687 10.4587 91.6539 10.4587C92.8208 10.4587 93.8106 10.8847 94.6378 11.7512ZM91.3584 17.6118C92.1266 17.6118 92.7618 17.3621 93.2788 16.848C93.7958 16.3192 94.0469 15.6876 94.0469 14.9092C94.0469 14.1307 93.7958 13.4844 93.2788 12.985C92.7618 12.4563 92.1266 12.2066 91.3584 12.2066C90.5903 12.2066 89.9551 12.4563 89.4381 12.985C88.9211 13.4991 88.67 14.1307 88.67 14.9092C88.67 15.6876 88.9211 16.3339 89.4381 16.848C89.9551 17.3621 90.6051 17.6118 91.3584 17.6118Z"
                    fill="#1C1C1C"
                  />
                  <path
                    d="M97.6364 9.07765C97.1785 8.60763 97.1785 7.88792 97.6364 7.43259C98.0943 6.96257 98.8477 6.96257 99.3056 7.43259C99.7636 7.88792 99.7636 8.59294 99.2909 9.07765C98.8182 9.53298 98.1091 9.53298 97.6364 9.07765ZM97.5478 19.1243V10.6786H99.3795V19.1243H97.5478Z"
                    fill="#1C1C1C"
                  />
                  <path
                    d="M102.334 18.0683C101.462 17.2017 101.019 16.1589 101.019 14.9104C101.019 13.6619 101.462 12.6191 102.334 11.7525C103.205 10.8859 104.269 10.4746 105.51 10.4746C106.765 10.4746 107.814 10.9006 108.686 11.7525C109.557 12.6044 110 13.6619 110 14.9104C110 16.1589 109.557 17.2017 108.686 18.0683C107.814 18.9349 106.765 19.3462 105.51 19.3462C104.269 19.3462 103.205 18.9202 102.334 18.0683ZM105.51 17.5689C106.263 17.5689 106.883 17.3192 107.4 16.8051C107.917 16.2911 108.169 15.6595 108.169 14.8957C108.169 14.1319 107.917 13.5003 107.4 12.9863C106.883 12.4722 106.263 12.2225 105.51 12.2225C104.756 12.2225 104.136 12.4722 103.619 12.9863C103.102 13.5003 102.851 14.1319 102.851 14.8957C102.851 15.6595 103.102 16.2911 103.619 16.8051C104.136 17.3192 104.771 17.5689 105.51 17.5689Z"
                    fill="#1C1C1C"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 11.2605C0 1.98749 1.98749 0 11.2605 0H14.7574C24.0304 0 26.0179 1.98749 26.0179 11.2605V14.7574C26.0179 24.0304 24.0304 26.0179 14.7574 26.0179H11.2605C1.98749 26.0179 0 24.0304 0 14.7574V11.2605ZM6.90396 6.50133C6.68305 6.50133 6.50396 6.68042 6.50396 6.90133V19.1103C6.50396 19.3312 6.68305 19.5103 6.90396 19.5103H10.8404V19.51H14.7765C14.9975 19.51 15.1765 19.3309 15.1765 19.11V15.5737C15.1765 15.3528 14.9975 15.1737 14.7765 15.1737H10.8404V6.90133C10.8404 6.68042 10.6613 6.50133 10.4404 6.50133H6.90396ZM17.345 10.8385C18.5424 10.8385 19.5131 9.86779 19.5131 8.67035C19.5131 7.47291 18.5424 6.50219 17.345 6.50219C16.1476 6.50219 15.1768 7.47291 15.1768 8.67035C15.1768 9.86779 16.1476 10.8385 17.345 10.8385Z"
                    fill="#1C1C1C"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </body>


  );
};



export default LinkbioScreen;
