import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

// import {
//   TitleLandingHeader,
// } from '../../../styles/styled.layout';



export const LandingHeaderStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* position: relative; */
  height: 100vh;
  min-height: 100vh;
`;


export const LandingHeaderContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-top: ${Sizes.toolbarHeight + 10}px;
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  z-index: 6;
  
  @media ${PlatformDevices.maxTabletL} {
    align-items: center;
    justify-content: center;
    padding-left: ${Sizes.mobilePaddingHorizontal};
    padding-right: ${Sizes.mobilePaddingHorizontal};
  }
`;



// export const HeaderContent = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   align-self: flex-start;
//   align-items: center;
//   justify-content: center;
//   padding-left: 5px;
//   padding-right: 5px;

//   @media ${PlatformDevices.maxTabletL} {
//     width: 100%;
//     align-items: center;
//     align-self: center;
//   }
// `;

// export const LandingAppLogo = styled.img`
//   object-fit: contain;

//   @media ${PlatformDevices.maxMobileM} {
//     width: 140px;
//     height: 140px;
//   }

//   @media ${PlatformDevices.minMobileM} {
//     width: 160px;
//     height: 160px;
//   }

//   @media ${PlatformDevices.minTablet} {
//     width: 180px;
//     height: 180px;
//   }

//   @media ${PlatformDevices.minTabletL} {
//     width: 200px;
//     height: 200px;
//   }
// `;


// export const TextsContent = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 20px;
// `;

// export const LandingHeaderTitle = styled(TitleLandingHeader)`
// `;

// export const SubtitleLandingHeader = styled.h3`
//   margin-top: 5px;
//   color: ${Colors.white};
//   font-weight: lighter;
//   font-style: italic;
//   text-align: center;

//   @media ${PlatformDevices.maxMobileM} {
//     font-size: 17px;
//     line-height: 22px;
//   }

//   @media ${PlatformDevices.minMobileM} {
//     font-size: 19px;
//     line-height: 23px;
//   }

//   @media ${PlatformDevices.minTablet} {
//     font-size: 21px;
//     line-height: 24px;
//   }

//   @media ${PlatformDevices.minTabletL} {
//     font-size: 23px;
//     line-height: 25px;
//   }
// `;



// export const LandingButtonView = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-top: 30px;
// `;



export const HeaderChildrenContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 6;
`;



export const LandingMouseDownView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 15px;

  @media ${PlatformDevices.minTablet} {
    align-items: center;
  }
`;

export const LandingMouseDownImage = styled.img`
  height: 60px;
  width: 40px;
  object-fit: cover;
  opacity: 0.6;
`;



export const LandingVideoOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${Colors.alertOverlay};
  z-index: 3;
`;

export const LandingImageBackground = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
`;

export const LandingVideoBackground = styled.video`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
`;
