import React from 'react';

import BarMusic from '../../components/BarMusic';
import { Screen } from '../../components/Composh/web';
import NameRoutes from '../../navigation/names';

import {
  HeaderWelcomeContent,
  ALogo,
  AImageLogo,
  MsoundInfo,
  MsoundLabel,
  MsoundBottomView,
  MsoundBottomSpan,
  MsoundBottomIcon,
} from './styled';



const WelcomeScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor='red'>

      <HeaderWelcomeContent>
        <ALogo
          href="/">
          <AImageLogo
            src="https://www.djhardwell.com/uploads/assets/1678802685.1648544846/static/site/img/logo.svg"
            alt="Hardwell"
          />
        </ALogo>
      </HeaderWelcomeContent>



      <MsoundInfo>

        <BarMusic />

        <MsoundLabel>
          For the best experience, turn up your volume.
        </MsoundLabel>

      </MsoundInfo>



      <MsoundBottomView
        href={NameRoutes.HomeScreen}>

        <MsoundBottomIcon>
          {/* {ICON SOUND} */}
        </MsoundBottomIcon>

        <MsoundBottomSpan>
          Continue
        </MsoundBottomSpan>

      </MsoundBottomView>

    </Screen>

  );
};



export default WelcomeScreen;
