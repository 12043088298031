import React from 'react';

import { Images } from '../../../shared/constants';

import {
  FooterDiv, DivContent, PageDescriptionDiv, Logo, Description,
  LinksDiv, QuickLinksDiv, TitleLinks, TextLinks, SocialContactDiv, SideLinksDiv, ButtonSideLinks,
} from './styled';



const FooterApp: React.FC = () => {
  return (
    <FooterDiv>
      <DivContent>
        <PageDescriptionDiv>
          <Logo
            src={Images.logoPersasIconWhite}
          />
          <Description>
            Making A first home purchase can be intimidating and stressful. Tracy s approach to service gave us
            the confidence that purchasing can bne intimidating and stressful.  Tracy s approach to service gave us
            the confidence that.
          </Description>

        </PageDescriptionDiv>

        <LinksDiv>

          <QuickLinksDiv>
            <TitleLinks>Quick Links</TitleLinks>
            <TextLinks>About us</TextLinks>
            <TextLinks>Biography</TextLinks>
            <TextLinks>Video</TextLinks>
            <TextLinks>Shop</TextLinks>
          </QuickLinksDiv>

          <SocialContactDiv>
            <TitleLinks>Social Contact</TitleLinks>
            <TextLinks>Services</TextLinks>
            <TextLinks>Help</TextLinks>
            <TextLinks>FAQ</TextLinks>
            <TextLinks>Support</TextLinks>
          </SocialContactDiv>

        </LinksDiv>

        <SideLinksDiv>
          <ButtonSideLinks>Links de Patreon</ButtonSideLinks>
          <ButtonSideLinks>Produção</ButtonSideLinks>
        </SideLinksDiv>
      </DivContent>
    </FooterDiv>

  );
};


export default FooterApp;
