import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const SeatedFollowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  padding: 30px 0;
`;

export const SeatedFollowTitle = styled.p`
  color: ${Colors.black};
  font-size: 16px;
  font-weight: 700;
`;

export const SeatedFollowButton = styled.a`
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  color: #000;
  border: 2px solid rgba(0, 0, 0, 1);
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  font-size: 12px;
  overflow: hidden;
  padding: 12px 3px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase !important;
`;
