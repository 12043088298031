import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  transparent?: boolean;
  show?: boolean;
  backgroundColor?: string;
}



const ScreenBackground = css`
  background-color: ${(props: IProps) => props.backgroundColor};
`;

export const NavBarScreen = styled.body`
  flex: 1;
  ${(props: IProps) => props.backgroundColor && ScreenBackground};
`;



export const NavBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0px;
  width: 100%;
  height: ${Sizes.toolbarHeight}px;
  background-color: ${(props: IProps) => props.transparent ? Colors.transparent : Colors.black};

  transition: all .4s ease-out;
  z-index: 999;
`;

export const NavBarContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
   
  @media ${PlatformDevices.maxTabletL} {
    padding-left: ${Sizes.mobilePaddingHorizontal};
    padding-right: ${Sizes.mobilePaddingHorizontal};
  }
`;

export const NavBarBrandButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  height: 100%;
  padding-right: 5px;
  cursor: pointer;

  @media ${PlatformDevices.maxTabletL} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const TermsPoliciesHeaderLogo = styled.img`
  width: 140px;
  height: 30px;
  margin-bottom: 4px;
  object-fit: contain;
  pointer-events: none;
`;

export const TermsPoliciesHeaderSeparator = styled.div`
  display: flex;
  flex-direction: column;
  height: 30px;
  width: 2px;
  margin-left: 10px;
  margin-right: 15px;
  background-color: ${Colors.menuLine};

  @media ${PlatformDevices.maxTablet} {
    display: none;
  }
`;

export const NavBarText = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  letter-spacing: 1px;
  pointer-events: none;
  transition: all .4s ease-out;

  @media ${PlatformDevices.maxTablet} {
    margin-bottom: 2px;
    font-size: 12px;
  }
`;



export const NavBarMenuItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
`;


export const NavBarMenuNotMobile = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  width: 100%;

  @media ${PlatformDevices.maxTabletL} {
    display: none;
  }
`;

export const NavBarCollapseNotMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
`;



export const NavBarItemNotMobile = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: 100%;
  padding-left: 20px; 
`;

export const NavBarButtonNotMobile = styled.p`
  color: ${Colors.white};
  font-size: 10px;
  letter-spacing: 2px;
  text-align: center;
  pointer-events: none;

  transition: all 0.20s;

  ${NavBarItemNotMobile}:hover & {
    color: ${Colors.accent};
    font-weight: bold;
  }

  ${NavBarItemNotMobile}:active & {
    color: ${Colors.primary};
  }
`;



export const NavBarMenuMobile = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 40px;
  height: 100%;

  @media ${PlatformDevices.minTabletL} {
    display: none;
  }
`;

export const NavBarMenuButtonMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  z-index: 1001;
`;



export const NavBarItemsMobile = styled.a`
  display:  ${(props: IProps) => props.show ? 'flex' : 'none'};
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  padding-top: 70px;
  padding-left: ${Sizes.mobilePaddingHorizontal};
  padding-right: ${Sizes.mobilePaddingHorizontal};
  background-color: ${Colors.menuOverlay};
  transition: 350ms;

  @media ${PlatformDevices.minTabletL} {
    display: none;
  }
`;


export const NavBarItemMobile = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 50px;
  padding-left: 5px;
  padding-right: 5px;
`;

export const NavBarButtonMobile = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  letter-spacing: 2px;
  text-align: center;
  pointer-events: none;

  transition: all 0.20s;

  ${NavBarItemMobile}:hover & {
    color: ${Colors.accent};
    font-weight: bold;
  }

  ${NavBarItemMobile}:active & {
    color: ${Colors.primary};
  }
`;



// Containers and Contents

export const NavBarPaddingTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Sizes.toolbarHeight}px;
`;
