import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../shared/constants';

import {
  PlatformDevices,
} from '../shared/utils/platforms.utils';



export const PaddingContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Sizes.desktopPaddingVertical};
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  padding-bottom: ${Sizes.desktopPaddingVertical};

  @media ${PlatformDevices.maxTabletM} {
    padding-top: ${Sizes.mobilePaddingVertical};
    padding-left: ${Sizes.mobilePaddingHorizontal};
    padding-right: ${Sizes.mobilePaddingHorizontal};
    padding-bottom: ${Sizes.mobilePaddingVertical};
  }
`;



export const TitleLandingHeader = styled.h1`
  width: 100%;
  color: ${Colors.white};
  font-weight: bold;
  text-align: center;

  @media ${PlatformDevices.maxMobileM} {
    font-size: 30px;
    line-height: 35px;
  }

  @media ${PlatformDevices.minMobileM} {
    font-size: 35px;
    line-height: 40px;
  }

  @media ${PlatformDevices.minTablet} {
    font-size: 45px;
    line-height: 50px;
  }

  @media ${PlatformDevices.minTabletL} {
    font-size: 55px;
    line-height: 60px;
  }
`;



export const TitleContents = styled.h2`
  color: ${Colors.textTitle};
  font-weight: 200;
  letter-spacing: 1px;

  @media ${PlatformDevices.maxMobileM} {
    font-size: 26px;
  }

  @media ${PlatformDevices.minMobileM} {
    font-size: 29px;
  }

  @media ${PlatformDevices.minMobileL} {
    font-size: 34px;
  }

  @media ${PlatformDevices.minTabletL} {
    font-size: 41px;
  }
`;



export const SubTitleContents = styled.h3`
  color: ${Colors.grayLight};
  font-weight: 400;

  @media ${PlatformDevices.maxMobileM} {
    font-size: 20px;
  }

  @media ${PlatformDevices.minMobileM} {
    font-size: 21px;
  }

  @media ${PlatformDevices.minMobileL} {
    font-size: 22px;
  }

  @media ${PlatformDevices.minTabletL} {
    font-size: 23px;
  }

  @media ${PlatformDevices.minLaptop} {
    font-size: 24px;
  }
`;



export const DescriptionContents = styled.h4`
  margin-top: 10px;
  color: ${Colors.grayLight};
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
`;
