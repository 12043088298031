import React from 'react';

import {
  SeatedFollowContainer,
  SeatedFollowTitle,
  SeatedFollowButton,
} from './styled';



const CardFollowArea: React.FC = () => {
  return (

    <SeatedFollowContainer>

      <SeatedFollowTitle>
        Get notified when new events are announced in your area
      </SeatedFollowTitle>

      <SeatedFollowButton
        href="https://go.seated.com/notifications/welcome/eaa7baac-9839-49b2-8797-5a1f3806b164"
        target="_blank">
        Follow now
      </SeatedFollowButton>

    </SeatedFollowContainer>

  );
};



export default CardFollowArea;
