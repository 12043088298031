import React from 'react';

import { useHistory } from 'react-router-dom';

import LandingHeader from '../../components/Containers/LandingHeader';
import PersonalReleasesSection from '../../components/Containers/PersonalReleases';
import FooterApp from '../../components/Footers/FooterApp';
import NavbarLanding from '../../components/Navbar/NavbarLanding';

import {
  Videos,
} from '../../shared/constants';

import HomeHeader from './HomeHeader';
import InstagramConnectSection from './InstagramConnect';
import PhotosGallerySection from './PhotosGallery';
import ShopmallSection from './Shopmall';
import UpcomingEventsSection from './UpcomingEvents';
import HomeVersionsMeSection from './VersionsMe';
import FooterPartner from '../../components/Footers/FooterPartner';
import FooterCopyright from '../../components/Footers/FooterCopyright';



const HomeScreen: React.FC = () => {
  const history = useHistory();



  return (

    <NavbarLanding>

      <LandingHeader
        id={'page_initial'}
        video={Videos.landingHomeAppVideo}>

        <HomeHeader />

      </LandingHeader>



      <PersonalReleasesSection />



      <HomeVersionsMeSection />



      {/* <UpcomingEventsSection /> */}



      {/* <PhotosGallerySection /> */}



      {/* <ShopmallSection /> */}



      {/* <InstagramConnectSection /> */}



      <FooterApp />
      <FooterPartner />
      <FooterCopyright />

    </NavbarLanding>

  );
};



export default HomeScreen;
