import styled from 'styled-components';



export const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const TitlesDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleHomeT = styled.h1`
  font-family: "CenturyGothic";
  color: #000;
  text-shadow: -0.7px 0.7px 0 #FFFF,
  0.7px 0.7px 0 #FFFF,
  0.7px -0.7px 0 #FFFF,
  -0.7px -0.7px 0 #FFF;
  font-weight: bold;
  font-size: 10.5rem;
`;


export const FirstSubtitleView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FirstTextSubtitle = styled.h2`
  font-family: "CenturyGothic";
  color: white;
  font-weight: bold;
  font-size: 3.8rem;
  line-height: 1.5;
`;


export const SecondSubtitle = styled.h3`
  font-family: "CenturyGothic";
  color: white;
  font-size: 1.8rem;
  max-width: 200px;
`;

export const NameAndUserNameDiv = styled.div`
  display: flex;
  flex-direction: column;
`;


export const Name = styled.h1`
  font-family: "CenturyGothic";
  color: white;
  font-size: 8.5rem;
  font-weight: bolder;
`;

export const Surname = styled.h1`
  font-family: "CenturyGothic";
  color: white;
  font-size: 8.5rem;
`;
