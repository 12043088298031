export const APP_ROUTES = {
  WelcomeScreen:                    '/',
  AccessScreen:                     '/access',

  LinkbioScreen:                    '/linkbio',
  
  HomeScreen:                       '/home',
  AboutMeScreen:                    '/about-me',
  ContactsScreen:                   '/contacts',
  MusicsScreen:                     '/musics',
  MusicsDetailsScreen:              '/musics/details',
  PhotosScreen:                     '/photos',
  ProjectsScreen:                   '/projects',
  PromotionsScreen:                 '/promotions',
  ScheduleScreen:                   '/schedule',
  ShopScreen:                       '/shop',
  VideosScreen:                     '/videos',

  ValentinesScreen:                 '/valentine',
};



const NameRoutes = {
  ...APP_ROUTES,
};



export default NameRoutes;
