import styled from 'styled-components';

import {
  Colors,
} from '../../shared/constants';



export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 402px;
`;



export const ScheduleMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${Colors.black};
`;



export const ScheduleListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  background-color: #fff;
  transition: all 350ms cubic-bezier(0.19,1,0.22,1);
  box-shadow: 0 0 8px 1px rgba(0,0,0,0.3);
`;



export const ScheduleUpcomingGigsContHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 55px;
  padding: 15px 30px;
  border-bottom: 1px solid #e8e8e8;
`;

export const ScheduleUpcomingGigsTitle = styled.h2`
  line-height: 1.1;
  font-size: 24px;
  text-transform: uppercase;
  color: #0f0f0f;
`;

export const ScheduleUpcomingTourOverview = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

export const ScheduleUpcomingTourFilter = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin-left: 12px;
`;



export const ScheduleUpcomingGigsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  width: 100%;
  padding: 30px;
`;



export const ScheduleUpcomingGigsHeader = styled.div`
  display: table;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 3px solid #0f0f0f;
  font-weight: 700;
  color: #0f0f0f;
  width: 100%;
`;

export const ScheduleUpcomingGigsHeaderDate = styled.p`
  font-size: 14px;
  line-height: 1.42857143;
  list-style: none;
  box-sizing: border-box;
  display: table-cell;
  vertical-align: top;
  width: 20%;
  color: #0f0f0f;
  font-weight: 600;
  text-transform: none;
`;

export const ScheduleUpcomingGigsHeaderEventInfo = styled.p`
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg,rgba(6,147,227,1) 0%,rgb(155,81,224) 100%);
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg,rgb(122,220,180) 0%,rgb(0,208,130) 100%);
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg,rgba(252,185,0,1) 0%,rgba(255,105,0,1) 100%);
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg,rgba(255,105,0,1) 0%,rgb(207,46,46) 100%);
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg,rgb(238,238,238) 0%,rgb(169,184,195) 100%);
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg,rgb(74,234,220) 0%,rgb(151,120,209) 20%,rgb(207,42,186) 40%,rgb(238,44,130) 60%,rgb(251,105,98) 80%,rgb(254,248,76) 100%);
  --wp--preset--gradient--blush-light-purple: linear-gradient(135deg,rgb(255,206,236) 0%,rgb(152,150,240) 100%);
  --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg,rgb(254,205,165) 0%,rgb(254,45,45) 50%,rgb(107,0,62) 100%);
  --wp--preset--gradient--luminous-dusk: linear-gradient(135deg,rgb(255,203,112) 0%,rgb(199,81,192) 50%,rgb(65,88,208) 100%);
  --wp--preset--gradient--pale-ocean: linear-gradient(135deg,rgb(255,245,203) 0%,rgb(182,227,212) 50%,rgb(51,167,181) 100%);
  --wp--preset--gradient--electric-grass: linear-gradient(135deg,rgb(202,248,128) 0%,rgb(113,206,126) 100%);
  --wp--preset--gradient--midnight: linear-gradient(135deg,rgb(2,3,129) 0%,rgb(40,116,252) 100%);
  --wp--preset--duotone--dark-grayscale: url('#wp-duotone-dark-grayscale');
  --wp--preset--duotone--grayscale: url('#wp-duotone-grayscale');
  --wp--preset--duotone--purple-yellow: url('#wp-duotone-purple-yellow');
  --wp--preset--duotone--blue-red: url('#wp-duotone-blue-red');
  --wp--preset--duotone--midnight: url('#wp-duotone-midnight');
  --wp--preset--duotone--magenta-yellow: url('#wp-duotone-magenta-yellow');
  --wp--preset--duotone--purple-green: url('#wp-duotone-purple-green');
  --wp--preset--duotone--blue-orange: url('#wp-duotone-blue-orange');
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
  font-size: 14px;
  line-height: 1.42857143;
  list-style: none;
  cursor: pointer;
  font-weight: 700;
  color: #0f0f0f;
  box-sizing: border-box;
  display: table-cell;
  vertical-align: top;
`;


export const ScheduleUpcomingSideBarBottom = styled.p`
  text-align: left;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  padding: 7px 10px;
  min-height: 25px;
  line-height: 1;
  background-color: #c60204;
`;
