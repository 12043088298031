import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const ReleaseImageBorder = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 180px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  width: 260px;
  min-width: 260px;
  max-width: 260px;
  height: 500px;
  min-height: 500px;
  max-height: 500px;
  border-color: ${Colors.primaryDark};
  border-style: solid;
  border-width: 0.5px;
  box-shadow: 0 0 1px ${Colors.primary}, 
    0 0 2px ${Colors.primary}, 
    0 0 4px ${Colors.primary}, 
    0 0 8px ${Colors.primary}, 
    inset 0 0 1px ${Colors.primary}, 
    inset 0 0 2px ${Colors.primary}, 
    inset 0 0 4px ${Colors.primary}, 
    inset 0 0 8px ${Colors.primary};
  transition: all .2s;

  :hover {
    box-shadow: 0 0 1px ${Colors.accent}, 
      0 0 2px ${Colors.accent}, 
      0 0 4px ${Colors.accent}, 
      0 0 8px ${Colors.accent}, 
      inset 0 0 1px ${Colors.accent}, 
      inset 0 0 2px ${Colors.accent}, 
      inset 0 0 4px ${Colors.accent}, 
      inset 0 0 8px ${Colors.accent};
  }
`;

export const ReleaseImageView = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-color: ${Colors.primary};
  border-style: solid;
  border-width: 1px;
  border-radius: 180px;
  transition: all .2s;

  :hover {
    border-color: ${Colors.accent};
  }
`;

export const ReleaseItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
