export const APPSITES = {
  contaGlobalApp:                   'http://conta.noitada.net/',
  contaOrganizerRegister:           'https://conta.noitada.net/register-company',

  noitadaSite:                      'http://www.noitada.net/',
};



const Vars = {
  ...APPSITES,
};



export default Vars;
