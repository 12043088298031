import React from 'react';

// import { Container } from './styles';



const MusicsDetailsScreen: React.FC = () => {
  return (

    <div>
      <h1>
        MusicsDetailsScreen
      </h1>
    </div>

  );
};



export default MusicsDetailsScreen;
