import React from 'react';

// import { Container } from './styles';



const VideosScreen: React.FC = () => {
  return (

    <div>
      <h1>
        VideosScreen
      </h1>
    </div>

  );
};



export default VideosScreen;
